<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('Account Information') }}</span>
      </template>

      <account-setting-general v-if="options.general" :user-info="userInfo" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('Change Password') }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- notification -->
    <b-tab v-if="false">
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import { BTabs, BTab } from 'bootstrap-vue';
import AccountSettingGeneral from './AccountSettingGeneral.vue';
import AccountSettingPassword from './AccountSettingPassword.vue';
import AccountSettingInformation from './AccountSettingInformation.vue';
import AccountSettingSocial from './AccountSettingSocial.vue';
import AccountSettingNotification from './AccountSettingNotification.vue';

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUser',
    }),
  },
  created() {
    console.log('account setting', this.userInfo);
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then((res) => {
      this.options = res.data;
    });
  },
};
</script>
