<template>
  <b-card v-if="userInfo">
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group :label="$t('Username')" label-for="account-username">
            <b-form-input
              :value="userInfo.username"
              disabled
              :placeholder="$t('Username')"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('ID')" label-for="account-name">
            <b-form-input :value="userInfo.id" disabled name="name" :placeholder="$t('ID')" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('Full name')" label-for="account-name">
            <b-form-input
              :value="userInfo.name"
              disabled
              name="name"
              :placeholder="$t('Full name')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('Team')" label-for="account-name">
            <b-form-input
              :value="userInfo.team && userInfo.team.name"
              disabled
              name="name"
              :placeholder="$t('Team')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('Role')" label-for="account-name">
            <v-select
              v-model="roles"
              multiple
              label="name"
              :reduce="(item) => item.id"
              :options="roles"
              class="w-100"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input :value="userInfo.email" disabled name="email" placeholder="Email" />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
            {{ $t('Save') }}
          </b-button>
        </b-col> -->
      </b-row>
      <b-row>
        <permission-list
          :per-check="permissions"
          :readonly="true"
          :permissions-data="permissions"
          :limited="false"
          :expanded="true"
        />
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import PermissionList from '@/views/system/permission/permission-list/PermissionList.vue';
import vSelect from 'vue-select';

export default {
  components: {
    BButton,
    BAvatar,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    PermissionList,
  },
  directives: {
    Ripple,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    roles() {
      return this.userInfo.roles.filter((r) => r) || [];
    },
    permissions() {
      return this.userInfo.permissions.map((permission) => ({
        name: permission.name,
        group: permission.group,
        limited: permission.scope,
        id: permission.id,
        permissionId: permission.id,
      }));
    },
  },
  created() {
    setTimeout(() => {
      console.log('general', this.$store);
    }, 3e3);
  },
  methods: { avatarText },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const avatar = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      // eslint-disable-next-line no-param-reassign
      avatar.value = base64;
    });
    return {
      avatar,
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
